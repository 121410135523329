import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { newGetThemeById } from '../store/themes/actions'
import {
  newGetContentById,
  clearCurrentContent
} from '../store/contents/actions'
import NewCreateContentForm from '../components/form/NewCreateContentForm'
import { occupationsObject } from '../utils/dicts'
import { isEmpty } from 'lodash'
import CircularProgress from '@material-ui/core/CircularProgress'

const NewContentsPage = ({
  componentAttribute,
  match,
  userOccupations,
  newGetThemeById,
  newGetContentById,
  currentTheme,
  currentContent,
  clearCurrentContent
}) => {
  const history = useHistory()
  const location = useLocation()

  const themeId = match.params.themeId
  const contentId = match.params.contentId

  React.useEffect(() => {
    if (themeId) {
      newGetThemeById({ id: themeId })
    }
    if (contentId) {
      newGetContentById({ id: contentId })
    }
    return () => {
      clearCurrentContent()
    }
  }, [])

  if (componentAttribute?.isCreate === true) {
    return (
      <NewCreateContentForm
        currentTheme={currentTheme}
        history={history}
        location={location}
      />
    )
  } else if (componentAttribute?.isEdit === true) {
    if (isEmpty(currentContent)) {
      return <CircularProgress />
    } else {
      return (
        <NewCreateContentForm
          currentTheme={currentTheme}
          history={history}
          initialValues={{ ...currentContent }}
          isEdit
          location={{
            state: {
              category: {
                label: currentContent?.category?.name,
                value: currentContent?.category_id
              },
              occupation: {
                label: occupationsObject[currentContent?.occupation?.name],
                value: currentContent?.occupation_id
              }
            }
          }}
          contentId={contentId}
        />
      )
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentSchool: state.school.currentSchool.school,
    currentTheme: state.themes.currentItem.item,
    currentContent: state.contents.currentItem.item
  }
}

const mapDispatchToProps = dispatch => ({
  newGetThemeById: data => dispatch(newGetThemeById(data)),
  newGetContentById: data => dispatch(newGetContentById(data)),
  clearCurrentContent: data => dispatch(clearCurrentContent(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(NewContentsPage)
