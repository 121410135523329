import React from 'react'
import { connect } from 'react-redux'
import { Field, Form, reduxForm, change, getFormValues } from 'redux-form'
import { Tooltip } from '@material-ui/core'

import { editUserRSPA } from '../store/users/actions'
import MaterialInput from '../components/shared/MaterialInput'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'

import {
  normalizeCPF,
  isValidCPF,
  required,
  validFullname,
  normalizePhone
} from '../utils/formUtils'

import StyledSVG from '../components/shared/StyledSVG'
import avatarTypeSVG from '../assets/icons/my-profile-photo-example.svg'
import emptyClassroomSVG from '../assets/icons/without-classrooms.svg'
import deleteSVG from '../assets/icons/pattern-delete-icon.svg'
import checkSVG from '../assets/icons/notification-check.svg'
import deleteAccountAlert from '../assets/delete-account-alert.png'
import Modal from '../components/modal/Modal'
import cameraSVG from '../assets/icons/camera-icon.svg'
import Button from '../components/button/Button'
import heic2any from 'heic2any'
import { handleAddAvatar } from '../utils/helpers'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '40px 30px',
    height: '100%',
    borderRadius: 8,
    backgroundColor: '#FFFFFF'
  },
  pageLabel: {
    display: 'flex',
    flexDirection: 'column',
    gap: 36,
    '& label': {
      color: '#868E96',
      fontWeight: 400,
      fontSize: 16
    },
    '& h1': {
      color: '#375F92',
      fontWeight: 700,
      fontSize: 24
    }
  },
  horizontalBar: {
    marginBottom: 27,
    width: '96%',
    height: 1,
    border: '1px solid #CED6E080'
  },
  editBtn: {
    width: 146,
    border: '2px solid #386093',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#386093',
    borderRadius: 8,
    '&:hover': {
      border: '2px solid #386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  photoEmpty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#D9D9D9',
    width: 140,
    height: 140,
    borderRadius: '50%',
    border: '1px dashed #868E96'
  },
  photoPreview: {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: 140,
    height: 140,
    borderRadius: '50%'
  },
  photoInfo: {
    fontWeight: 400,
    fontSize: 10,
    color: '#868E96'
  },
  floatEditAvatar: {
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: '#386093CC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rmvBtn: {
    zIndex: 1,
    right: '-1px',
    top: '-1px',
    color: '#FFFFFF',
    margin: '5px'
  },
  floatRemoveAvatar: {
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    top: 0,
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: '#EF7C8FCC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  avatarSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    '& .avatarLabel': {
      color: '#555555',
      fontWeight: 700,
      fontSize: 16
    }
  },
  avatarEdit: {
    display: 'flex',
    gap: 30,
    alignItems: 'center'
  },
  avatarType: {
    backgroundColor: '#F7F8FA',
    position: 'relative',
    height: 180,
    width: 300,
    borderRadius: 16,
    display: 'flex',
    gap: 40,
    '& .text': {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      padding: '10px 0px 0px 10px',
      '& span': {
        margin: 0,
        fontWeight: 600,
        color: '#EF7C8F',
        fontSize: 8
      },
      '& h1': {
        margin: 0,
        maxWidth: 120,
        fontWeight: 700,
        color: '#386093',
        fontSize: 16
      },
      '& p': {
        margin: 0,
        maxWidth: 130,
        fontWeight: 400,
        color: '#868E96',
        fontSize: 10
      }
    },
    '& .img': {
      position: 'absolute',
      right: -85
    }
  },
  contentWrapper: {
    display: 'flex',
    maxWidth: '75%',
    justifyContent: 'space-between'
  },
  photoSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  },
  paddingToEditBtn: {
    paddingBottom: 30
  },
  teste: {
    marginBottom: 10
  },
  userInfoSection: {
    marginTop: 40,
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 24,
    rowGap: 20,
    '& .input': {
      width: '40%',
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      '& label': {
        color: '#555555',
        fontWeight: 700,
        fontSize: 16
      }
    }
  },
  disabled: {
    '& label': {
      color: '#8080808C !important'
    }
  },
  schoolInfos: {
    margin: 0,
    marginTop: 40,
    '& h1': {
      color: '#375F92',
      fontSize: 24,
      fontWeight: 700
    }
  },
  classroomSection: {
    '& span': {
      fontSize: 16,
      fontWeight: 700,
      color: '#555555'
    }
  },
  classrooms: {
    margin: '8px 0px 24px 0px',
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 8,
    rowGap: 10
  },
  classroom: {
    padding: '4px 7.5px 4px 7.5px',
    gap: '8px',
    borderRadius: 4,
    backgroundColor: '#8080801A',
    '& span': {
      color: '#8080808C',
      fontWeight: 400,
      fontSize: 14
    }
  },
  deleteSection: {
    width: 'fit-content',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '& span': {
      fontSize: 14,
      fontWeight: 700,
      color: '#EF7C8F'
    },
    '& svg': {
      '& path': {
        stroke: '#EF7C8F'
      }
    }
  },
  saveSection: {
    marginTop: 70,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  buttonWrapper: {
    '& div': {
      '& .Mui-disabled': {
        cursor: 'pointer',
        pointerEvents: 'auto'
      }
    }
  },
  createBtn: {
    color: '#FFFF',
    backgroundColor: '#386093',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px',
    '& svg': {
      '& path': {
        stroke: '#FFFF'
      }
    }
  },
  modalRoot: {
    fontFamily: 'Montserrat, Source Sans Pro'
  },
  modalText: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    rowGap: 16,
    '& h1': {
      margin: 0,
      color: '#EF7B8E',
      fontWeight: 700,
      fontSize: 24
    },
    '& p, span': {
      margin: 0,
      maxWidth: 500,
      fontWeight: 400,
      fontSize: 16
    }
  },
  btnsModal: {
    marginTop: 10,
    columnGap: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cancelBtn: {
    backgroundColor: '#EF7C8F',
    width: 169,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  continueBtn: {
    border: '1px solid #386093',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#386093',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  emptyProfile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& .label': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'fit-content',
      '& span, h1': {
        margin: 0
      },
      '& h1': {
        fontSize: 20,
        color: '#ef7b8e'
      },
      '& span': {
        fontSize: 16
      }
    }
  }
}))

const MyProfilePage = props => {
  const {
    handleSubmit,
    currentUser,
    currentSchool,
    change,
    fieldValues,
    valid,
    history
  } = props

  const classes = useStyles()
  const avatarRef = React.useRef(null)
  const [avatarBlob, setAvatarBlob] = React.useState(null)
  const [confirmModal, setConfirmModal] = React.useState(false)
  const [validateCpf, setValidateCpf] = React.useState(null)
  const hasAvatar = !!avatarBlob || !!currentUser.attributes.avatar_url

  const handleOnChangeAvatar = event => {
    handleAddAvatar(
      event,
      null,
      alert,
      param =>
        setAvatarBlob(prevState => {
          change('avatar', param)
          return param
        }),
      heic2any
    )
  }

  const handleDeleteAvatar = () => {
    setAvatarBlob(null)
    change('avatar', 'delete')
  }

  return (
    <div className={classes.root}>
      <Form onSubmit={handleSubmit}>
        <div className={classes.pageLabel}>
          <label>
            Edite suas informações, configure preferências e gerencie sua conta.
          </label>
          <h1>Configurações do perfil</h1>
        </div>
        <div className={classes.horizontalBar} />

        <div className={classes.avatarSection}>
          <label className='avatarLabel'>Alterar foto de perfil</label>
          <div className={classes.contentWrapper}>
            <div className={classes.avatarEdit}>
              {hasAvatar && fieldValues?.avatar !== 'delete' ? (
                <div className={classes.photoSection}>
                  {!avatarBlob ? (
                    <div
                      style={{
                        backgroundImage: `url(${
                          currentUser?.attributes?.avatar_url
                        })`
                      }}
                      className={classes.photoPreview}
                    >
                      <div
                        onClick={() =>
                          avatarRef.current && avatarRef.current.click()
                        }
                        className={classes.floatEditAvatar}
                      >
                        <StyledSVG src={cameraSVG} width={20} height={20} />
                      </div>
                      <div className={classes.floatRemoveAvatar}>
                        <HighlightOffIcon
                          className={classes.rmvBtn}
                          onClick={() => handleDeleteAvatar()}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        backgroundImage: `url(${URL.createObjectURL(
                          avatarBlob
                        )})`
                      }}
                      className={classes.photoPreview}
                    >
                      <div
                        onClick={() =>
                          avatarRef.current && avatarRef.current.click()
                        }
                        className={classes.floatEditAvatar}
                      >
                        <StyledSVG src={cameraSVG} width={20} height={20} />
                      </div>
                      <div className={classes.floatRemoveAvatar}>
                        <HighlightOffIcon
                          className={classes.rmvBtn}
                          onClick={() => handleDeleteAvatar()}
                        />
                      </div>
                    </div>
                  )}
                  <span className={classes.photoInfo}>
                    Min. 200x200 px.<br />.png, .jpg, .jpeg ou .heic
                  </span>
                </div>
              ) : (
                <div className={classes.photoSection}>
                  <div className={classes.photoEmpty}>
                    <StyledSVG src={cameraSVG} width={50} height={50} />
                  </div>
                  <span className={classes.photoInfo}>
                    Min. 200x200 px.<br />.png, .jpg, .jpeg ou .heic
                  </span>
                </div>
              )}
              <input
                accept='.png, .jpg, .jpeg, .heic'
                hidden
                name='avatar'
                onChange={handleOnChangeAvatar}
                ref={avatarRef}
                type='file'
              />
              <Button
                type='button'
                variant={'outlined'}
                rootClass={classes.paddingToEditBtn}
                onClick={() => avatarRef.current && avatarRef.current.click()}
                className={classes.editBtn}
              >
                Editar
              </Button>
            </div>
            <div className={classes.avatarType}>
              <div className='text'>
                <span>#DICAVERSAR</span>
                <h1>Selecione uma boa foto</h1>
                <p>
                  Escolha uma foto que mostre seu rosto claramente. Isso ajuda
                  os outros a reconhecê-lo.
                </p>
              </div>
              <div className='img'>
                <img src={avatarTypeSVG} />
              </div>
            </div>
          </div>
        </div>

        <div className={classes.userInfoSection}>
          <div className={'input'}>
            <label>Nome e sobrenome</label>
            <Field
              name={'name'}
              placeholder={'Informe o nome e sobrenome'}
              component={MaterialInput}
              validate={[required, validFullname]}
            />
          </div>
          <div className={`input`}>
            <label>CPF</label>
            <Field
              name={'taxpayer_number'}
              placeholder={'Informe o CPF'}
              validateField={selfValidate => setValidateCpf(selfValidate)}
              normalize={normalizeCPF}
              component={MaterialInput}
              validate={[isValidCPF]}
            />
          </div>
          <div className={'input'}>
            <label>Telefone</label>
            <Field
              name={'phone'}
              placeholder='Informe o telefone de contato'
              autoComplete='off'
              normalize={normalizePhone}
              component={MaterialInput}
            />
          </div>
          <div className={`input ${classes.disabled}`}>
            <label>E-mail</label>
            <Field name='email' disabled component={MaterialInput} />
          </div>
        </div>

        <div className={classes.schoolInfos}>
          <h1>Informações da escola</h1>
          <div className={classes.horizontalBar} />
          <div className={classes.classroomSection}>
            <span>Turmas</span>
            <div className={classes.classrooms}>
              {currentUser.attributes.classrooms.filter(
                item => item?.school_id === currentSchool?.school_id
              ).length > 0 ? (
                currentUser.attributes.classrooms
                  .filter(item => item.school_id === currentSchool.school_id)
                  .map(classroom => {
                    return (
                      <div key={classroom.id} className={classes.classroom}>
                        <span>{classroom.name}</span>
                      </div>
                    )
                  })
              ) : (
                <div className={classes.emptyProfile}>
                  <StyledSVG
                    src={emptyClassroomSVG}
                    width={'80%'}
                    height={'80%'}
                  />
                  <div className='label'>
                    <h1>Nenhuma turma encontrada</h1>
                    <span>
                      Este professor ainda não possui turmas cadastradas.
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={classes.horizontalBar} />
        </div>

        <div
          onClick={() => setConfirmModal(true)}
          className={classes.deleteSection}
        >
          <StyledSVG src={deleteSVG} height={24} width={24} />
          <span>Deletar conta</span>
        </div>

        <div className={classes.saveSection}>
          <Tooltip
            placement='top'
            title={validateCpf ? '' : 'Informe um CPF válido para continuar'}
          >
            <div className={classes.buttonWrapper}>
              <Button
                type='submit'
                className={classes.createBtn}
                startIcon={<StyledSVG src={checkSVG} width={14} height={14} />}
                disabled={!valid}
              >
                Salvar
              </Button>
            </div>
          </Tooltip>
        </div>
      </Form>
      <Modal
        open={confirmModal}
        handleClose={() => setConfirmModal(prev => !prev)}
      >
        <div className={classes.modalRoot}>
          <img src={deleteAccountAlert} />
          <div className={classes.modalText}>
            <h1>Confirmação de exclusão de conta</h1>
            <p>
              Você está prestes a excluir sua conta de forma permanente. Após a
              exclusão, todos os seus dados serão removidos e o acesso à
              plataforma será perdido.
            </p>
            <span>
              Caso não deseje continuar, clique em &apos;&apos;<strong>
                cancelar
              </strong>&apos;&apos; para cancelar.
            </span>
          </div>
          <div className={classes.btnsModal}>
            <Button
              className={classes.cancelBtn}
              startIcon={<CloseIcon />}
              onClick={() => {
                setConfirmModal(false)
              }}
            >
              Cancelar
            </Button>
            <Button
              className={classes.continueBtn}
              variant='outlined'
              startIcon={
                <KeyboardBackspaceIcon
                  style={{ transform: 'rotate(180deg)' }}
                />
              }
              onClick={() => {
                history.push('settings/delete_profile')
              }}
            >
              Seguir
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    currentSchool: state.school.currentSchool,
    currentUser: state.auth.user.data,
    fieldValues: getFormValues('myProfileForm')(state),
    initialValues: {
      name: state.auth.user.data.attributes.name,
      taxpayer_number: state.auth.user.data.attributes.taxpayer_number,
      phone: state.auth.user.data.attributes.phone,
      email: state.auth.user.data.attributes.email
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    change: (field, data) => dispatch(change('myProfileForm', field, data)),
    editUserRSPA: data => dispatch(editUserRSPA.request(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'myProfileForm',
    enableReinitialize: true,
    onSubmit: async (values, dispatch, props) => {
      const { editUserRSPA, currentUser } = props
      await editUserRSPA({ userId: currentUser.id, values })
        .then(res => {
          return res
        })
        .catch(err => {
          console.error(err)
        })
    }
  })(MyProfilePage)
)
