import { all, call, takeLatest, put, select } from 'redux-saga/effects'
import { jsonToFormdata } from '../../utils/mappers'
import { showAlertMessage } from '../alert/actions'
import {
  rejectPromiseAction,
  resolvePromiseAction
} from 'redux-saga-promise-actions'

import * as actions from './actions'
import * as services from './services'

export function * fetchEvents ({ payload }) {
  try {
    const response = yield call(services.fetchEvents, payload)
    yield put(
      actions.getEventsSuccessful({ data: response.data, meta: response.meta })
    )
  } catch (error) {
    yield put(actions.getEventsFailure(error))
  }
}

export function * fetchEventByIdRequest ({ payload }) {
  try {
    const response = yield call(services.fetchEventById, payload)
    let destinationsData
    const eventDestinationType =
      response.destinations.length && response.destinations[0].destination_type
    if (!payload.withoutDestinations) {
      const eventDestinations = yield call(services.fetchEventDestinations, {
        id: response.id
      })
      destinationsData = {
        items: eventDestinations.data,
        type: eventDestinationType,
        pagination: {
          total: eventDestinations.meta.total_count,
          pageCount: eventDestinations.meta.page_count
        }
      }
    }
    const successfulPayload = {
      ...(payload.withoutDestinations
        ? { data: response, destinations: { type: eventDestinationType } }
        : { data: response, destinations: destinationsData })
    }
    yield put(actions.getEventByIdSuccessful(successfulPayload))
    yield put(actions.getEventComments({ id: payload.id }))
  } catch (error) {
    yield put(actions.getEventByIdFailure(error))
  }
}

export function * createEventRequest ({ payload }) {
  try {
    const fd = new FormData()
    const hasDestinationSchool =
      payload.school_id && !payload.classroom_id && !payload.grade_id
    fd.append('data[type]', 'events')
    const link = payload.links
    /* yield payload.links &&
      payload.links.forEach(link => {
        fd.append('data[attributes][files_attributes][][note]', link)
      }) */
    yield payload.files &&
      payload.files.forEach(file => {
        fd.append('data[attributes][files_attributes][][file]', file)
      })
    if (!payload?.destinations || payload?.destinations?.length < 1) {
      payload.destinationType?.length > 0 &&
        payload.destinationType.forEach(destinationType => {
          payload.classroom_id &&
            payload.classroom_id.forEach(classroom => {
              fd.append(
                'data[attributes][destinations_attributes][][classroom_id]',
                classroom
              )
              destinationType.value !== 'all' &&
                fd.append(
                  'data[attributes][destinations_attributes][][destination_type]',
                  destinationType.value
                )
            })
        })
    }
    hasDestinationSchool &&
      fd.append(
        'data[attributes][destinations_attributes][][school_id]',
        payload.school_id
      )
    payload?.destinations?.length > 0 &&
      payload.destinations.forEach(destination => {
        fd.append(
          'data[attributes][destinations_attributes][][user_id]',
          destination.value
        )
        fd.append(
          'data[attributes][destinations_attributes][][classroom_id]',
          payload.classroom_id[0]
        )
      })
    delete payload.files
    delete payload.destinations
    delete payload.destinationType
    delete payload.classroom_id
    delete payload.links
    yield jsonToFormdata(fd, payload)
    const response = yield call(services.createEvent, fd)
    if (response.data && link) {
      const data = {
        data: {
          type: 'event_files',
          attributes: {
            event_id: response.data.id,
            note: link
          }
        }
      }
      yield call(services.createEventFile, data)
    }

    yield put(
      actions.createEventsSuccessful({
        id: response.data.id,
        ...response.data.attributes
      })
    )

    const eventType = {
      communications: 'Comunicado',
      events: 'Evento',
      images: 'Evento'
    }
    yield put(
      showAlertMessage({
        message: `${eventType[payload.event_type]} enviado com sucesso!`,
        severity: 'success'
      })
    )
  } catch (error) {
    yield put(actions.createEventsFailure(error))
  }
}

export function * getEventDestinationsRequest ({ payload }) {
  try {
    const eventDestinations = yield call(
      services.fetchEventDestinations,
      payload
    )
    const destinationsData = {
      items: eventDestinations.data,
      pagination: {
        total: eventDestinations.meta.total_count,
        pageCount: eventDestinations.meta.page_count
      }
    }
    yield put(actions.getEventDestinationByIdSuccessful(destinationsData))
  } catch (error) {
    yield put(actions.getEventDestinationByIdFailure(error))
  }
}

export function * getEventCommentsRequest ({ payload }) {
  try {
    const response = yield call(services.fetchEventComments, payload)
    yield put(actions.getEventCommentsSuccessful(response.data))
  } catch (error) {
    yield put(actions.getEventCommentsFailure(error))
  }
}

export function * editEventCommentRequest ({ payload }) {
  try {
    yield call(services.editEventComment, payload)
    yield put(
      showAlertMessage({
        message: 'Comentário editado com sucesso!',
        severity: 'success'
      })
    )
  } catch (error) {
    yield put(
      showAlertMessage({
        message: 'Erro ao editar comentário!',
        severity: 'error'
      })
    )
  }
}

export function * createEventCommentsRequest ({ payload }) {
  try {
    const { auth } = yield select()
    const response = yield call(services.createEventComment, payload)
    yield put(actions.getEventComments({ id: response.event_id }))
    yield put(
      actions.createEventCommentSuccessful({
        ...response,
        user: { id: auth.user.data.id, ...auth.user.data.attributes }
      })
    )
    yield put(
      showAlertMessage({
        message: 'Comentário enviado com sucesso!',
        severity: 'success'
      })
    )
  } catch (error) {
    yield put(actions.createEventCommentFailure(error))
    yield put(
      showAlertMessage({
        message: 'Erro ao enviar comentário!',
        severity: 'error'
      })
    )
  }
}

export function * getEventFilesRequest ({ payload }) {
  try {
    const response = yield call(services.fetchEventFiles, payload)
    yield put(actions.getEventFilesSuccessful(response))
  } catch (error) {
    yield put(actions.getEventFilesFailure(error))
  }
}

export function * getEventLikesRequest ({ payload }) {
  try {
    const response = yield call(services.fetchEventLikes, payload)
    yield put(actions.getEventLikesSuccessful(response.data))
  } catch (error) {
    yield put(actions.getEventLikesFailure(error))
  }
}

export function * deleteEventRequest ({ payload }) {
  try {
    yield call(services.deleteEvent, payload)
    yield put(actions.deleteEventSuccessful(payload))
    yield put(
      showAlertMessage({
        message: 'Deletado com sucesso.',
        severity: 'success'
      })
    )
  } catch (error) {
    yield put(actions.deleteEventFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha ao deletar.',
        severity: 'error'
      })
    )
  }
}

export function * editEventRequest ({ payload }) {
  try {
    const fd = new FormData()
    fd.append('data[type]', 'events')
    fd.append('data[id]', payload.id)
    const link = payload.data.links
    const coverImage = payload.data.cover_image
    payload.data.files &&
      payload.data.files.forEach(file => {
        fd.append('data[attributes][files_attributes][][file]', file)
      })
    if (payload.filesToDelete && payload.filesToDelete.length > 0) {
      yield all(
        payload.filesToDelete.map(filesId => {
          return call(services.deleteEventFile, filesId)
        })
      )
    }
    if (coverImage === 'delete') {
      yield call(services.deleteCoverImage, {
        id: payload.id
      })
    } else if (coverImage) {
      fd.append('data[attributes]cover_image', coverImage)
    }
    if (link) {
      const data = {
        data: {
          type: 'event_files',
          attributes: {
            event_id: payload.id,
            note: link
          }
        }
      }
      yield call(services.createEventFile, data)
    }

    delete payload.data.files
    delete payload.data.destinations
    delete payload.data.destinationType
    delete payload.data.links
    delete payload.data.cover_image
    yield jsonToFormdata(fd, payload.data)
    const response = yield call(services.editEvent, {
      id: payload.id,
      data: fd
    })
    yield put(
      actions.editEventSuccessful({
        id: response.data.id,
        ...response.data.attributes
      })
    )
    const eventType = {
      communications: 'Comunicado',
      events: 'Evento',
      images: 'Evento'
    }
    yield put(
      showAlertMessage({
        message: `${
          eventType[payload.data.event_type]
        } atualizado com sucesso!`,
        severity: 'success'
      })
    )
  } catch (error) {
    yield put(actions.editEventFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha na atualização',
        severity: 'error'
      })
    )
  }
}

export function * getEventDestinationsRSPARequest (action) {
  try {
    const { payload: { endpoint, params } } = action
    const response = yield call(services.fetchDestinationsEvents, {
      params,
      endpoint
    })
    resolvePromiseAction(action, response)
  } catch (err) {
    rejectPromiseAction(action, err)
  }
}

export function * getEventForAllDestinationsRSPARequest (action) {
  try {
    const { payload: { endpoint, params } } = action
    const response = yield call(services.fetchAllDestinationsEvents, {
      params,
      endpoint
    })
    resolvePromiseAction(action, response)
  } catch (err) {
    rejectPromiseAction(action, err)
  }
}

export function * getClassroomsCountBySchoolRSPARequest (action) {
  try {
    const { payload: { schoolId } } = action
    const response = yield call(services.fetchClassroomsCountBySchool, schoolId)
    resolvePromiseAction(action, response)
  } catch (err) {
    rejectPromiseAction(action, err)
  }
}

export function * getEventDetailsRSPARequest (action) {
  try {
    const { payload: { eventId } } = action
    const response = yield call(services.fetchEventDetails, eventId)
    resolvePromiseAction(action, response)
  } catch (err) {
    rejectPromiseAction(action, err)
  }
}

export function * getEventConfirmationRSPARequest (action) {
  try {
    const { payload: { data } } = action
    const response = yield call(services.fetchEventConfirmation, data)
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * getEventNotConfirmedRSPARequest (action) {
  try {
    const { payload: { data } } = action
    const response = yield call(services.fetchEventNonConfirmations, data)
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * watchFetchEvents () {
  yield takeLatest(actions.getEvents, fetchEvents)
  yield takeLatest(actions.createEvents, createEventRequest)
  yield takeLatest(actions.getEventComments, getEventCommentsRequest)
  yield takeLatest(actions.createEventComment, createEventCommentsRequest)
  yield takeLatest(actions.editEventComment, editEventCommentRequest)
  yield takeLatest(actions.getEventById, fetchEventByIdRequest)
  yield takeLatest(actions.getEventFiles, getEventFilesRequest)
  yield takeLatest(actions.getEventLikes, getEventLikesRequest)
  yield takeLatest(actions.deleteEvent, deleteEventRequest)
  yield takeLatest(actions.editEvent, editEventRequest)
  yield takeLatest(actions.getEventDestinationById, getEventDestinationsRequest)
  yield takeLatest(
    actions.getEventDetailsRSPA.request,
    getEventDetailsRSPARequest
  )
  yield takeLatest(
    actions.getEventDestinationsRSPA.request,
    getEventDestinationsRSPARequest
  )
  yield takeLatest(
    actions.getEventForAllDestinationsRSPA.request,
    getEventForAllDestinationsRSPARequest
  )
  yield takeLatest(
    actions.getClassroomsCountBySchoolRSPA.request,
    getClassroomsCountBySchoolRSPARequest
  )
  yield takeLatest(
    actions.getEventConfirmationRSPA.request,
    getEventConfirmationRSPARequest
  )
  yield takeLatest(
    actions.getEventNotConfirmedRSPA.request,
    getEventNotConfirmedRSPARequest
  )
}

export default function * eventsSagas () {
  yield all([watchFetchEvents()])
}
