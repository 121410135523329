import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { submit } from 'redux-form'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import MaterialModal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import {
  getEventById,
  getEventDestinationById,
  cleanEventSuccessful,
  cleanCurrentDestinationsEvent
} from '../../store/events/actions'
import StyledSVG from '../shared/StyledSVG'
import ShowPendingAnnouncement from '../shared/ShowPendingAnnouncement'
import ShowRejectAnnouncement from '../shared/ShowRejectAnnouncement'

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import editSVG from '../../assets/icons/pattern-edit-icon.svg'
import heartSVG from '../../assets/icons/heart.svg'
import replySVG from '../../assets/icons/reply-comment.svg'
import titleSVG from '../../assets/icons/announcement-title.svg'
import clockSVG from '../../assets/icons/clock-icon.svg'
import calendarSVG from '../../assets/icons/calendar.svg'
import descriptionSVG from '../../assets/icons/announcement-description.svg'
import destinationsSVG from '../../assets/icons/announcement-destinations.svg'
import commentsSVG from '../../assets/icons/comments-icon.svg'
import viewedSVG from '../../assets/icons/announcement-views.svg'
import confirmedSVG from '../../assets/icons/announcement-confirmation.svg'
import PaginatedAsyncTable from '../table/PaginatedAsyncTable'
import UserAvatar from '../shared/UserAvatar'
import { CircularProgress, LinearProgress } from '@material-ui/core'
import { occupationsObject } from '../../utils/dicts'

import moment from 'moment'
import createReduxForm from '../form/ReplyCommentForm'
import CommentForm from '../form/CommentForm'
import CustomTable from '../table/CustomTable'
import Button from '../button/Button'
import { backToThePage } from '../../store/pagination/actions'
import {
  destinationTypeEvent,
  roles,
  statusEvent,
  reportsShift
} from '../../utils/constants'
import ShowEventFiles from '../shared/ShowEventFiles'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: 34,
    '& h1, h4': {
      color: '#4D5E80'
    },
    '& .MuiGrid-container': {
      [theme.breakpoints.down('426')]: {
        display: 'block',
        '& .MuiGrid-item': {
          maxWidth: 'unset',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          alignItems: 'baseline',
          columnGap: 12,
          justifyContent: 'flex-start'
        }
      }
    }
  },
  midContent: {
    display: 'flex',
    minHeight: '500px',
    marginTop: '16px',
    gap: '16px',
    '& div.firstColumn': {
      width: '65%',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px'
    },
    '& div.secondColumn': {
      width: '35%',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px'
    },
    [theme.breakpoints.down('950')]: {
      flexDirection: 'column',
      '& div.firstColumn': {
        width: '100%'
      },
      '& div.secondColumn': {
        width: '100%'
      }
    }
  },
  sectionsIntoMidContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    borderRadius: 8,
    backgroundColor: 'white',
    padding: 32,
    '& h1.description, h1.title, h1.destinations': {
      [theme.breakpoints.down('1100')]: {
        fontSize: '18px !important'
      }
    }
  },
  btnsSection: {
    display: 'flex',
    padding: 32
  },
  title: {
    flexDirection: 'column',
    gap: '8px'
  },
  description: {
    flexDirection: 'column',
    gap: '8px',
    justifyContent: 'flex-start'
  },
  labelEvent: {
    '& p': {
      color: theme.palette.text.secondary,
      marginTop: 0
    }
  },
  viewMore: {
    border: 'none',
    background: 'none',
    color: theme.palette.info.main,
    cursor: 'pointer',
    fontSize: 16,
    fontWeight: 700,
    textDecorationLine: 'underline',
    padding: 0,
    [theme.breakpoints.down('1100')]: {
      fontSize: 12
    }
  },
  viewedBy: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#386093',
    '& h1': {
      fontSize: '24px',
      color: 'white'
    },
    [theme.breakpoints.down('1200')]: {
      '& h1': {
        fontSize: '16px'
      },
      '& svg': {
        width: 70,
        height: 45
      }
    },
    [theme.breakpoints.down('950')]: {
      padding: 16
    }
  },
  confirmedBy: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#9DD2DA',
    '& h1': {
      fontSize: '24px',
      color: '#386093'
    },
    [theme.breakpoints.down('1200')]: {
      '& h1': {
        fontSize: '16px'
      },
      '& svg': {
        width: 70,
        height: 45
      }
    },
    [theme.breakpoints.down('950')]: {
      padding: 16
    }
  },
  listForModal: {
    '& div.info': {
      display: 'flex',
      flexDirection: 'column',
      gap: 5
    },
    '& span': {
      borderRadius: 32,
      display: 'inline-flex',
      alignItems: 'center'
    },
    [theme.breakpoints.down('426')]: {
      '& div.info': {
        gap: 0
      },
      marginBottom: 12
    }
  },
  header: {
    margin: '21px auto'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('426')]: {
      padding: '20px',
      display: 'flex',
      justifyContent: 'center'
    }
  },
  modalRoot: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '29px 35px 50px',
    [theme.breakpoints.down('426')]: {
      maxWidth: '95%'
    },
    color: theme.palette.text.primary,
    maxHeight: 600,
    width: 825,
    overflow: 'auto',
    '& button': {
      fontWeight: 700,
      textTransform: 'capitalize'
    }
  },
  closeIcon: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '-20px',
    '& svg': {
      fill: theme.palette.text.primary,
      height: 16,
      width: 16
    }
  },
  goBackContainer: {
    textAlign: 'end',
    marginBottom: '2rem',
    marginTop: 25,
    [theme.breakpoints.down('950')]: {
      display: 'flex',
      alignItems: 'center !important',
      justifyContent: 'center',
      '& button': {
        width: '200px'
      }
    }
  },
  fadeIn: {
    opacity: 1,
    transition: 'opacity 0.5s ease-in-out'
  },
  fadeOut: {
    opacity: 0,
    transition: 'opacity 0.5s ease-in-out'
  },
  tableClassname: {
    width: '65%',
    borderRadius: 8,
    padding: 15
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  comment: {
    fontSize: 24,
    margin: 0,
    [theme.breakpoints.down('1100')]: {
      fontSize: 16
    }
  },
  commentHeader: {
    [theme.breakpoints.down('690')]: {
      flexDirection: 'column',
      alignItems: 'flex-start !important'
    }
  },
  commentDate: {
    display: 'flex',
    gap: 12,
    [theme.breakpoints.down('690')]: {
      marginLeft: '50px'
    }
  },
  commentBtn: {
    alignItems: 'center',
    gap: 8,
    [theme.breakpoints.down('400')]: {
      '& p': {
        display: 'none'
      }
    }
  },
  detailsSection: {
    display: 'flex',
    columnGap: '12px'
  },
  modalSection: {
    backgroundColor: 'white',
    padding: 30
  },
  expandedReply: {
    borderRadius: '0px 0px 16px 16px'
  },
  commentReplyBorder: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    border: 'none !important'
  },
  replySection: {
    transition: 'border-radius 0.5s ease',
    backgroundColor: '#FFFFFF',
    height: '48px',
    border: '1px solid #DADADA'
  },
  replyBtn: {
    cursor: 'pointer',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    marginLeft: 20,
    '& p': {
      color: '#4D5E80',
      fontWeight: 600
    }
  },
  commentReply: {
    overflow: 'hidden',
    transition:
      'height 0.5s ease-in-out, border-radius 0.5s ease, padding 0.5s ease',
    borderRadius: '0px 0px 16px 16px',
    borderTop: 'none',
    border: '1px solid #DADADA',
    padding: '30px 32px 32px 37px',
    marginBottom: 32
  },
  commentReplyInput: {
    border: '1px solid #00ACDB80',
    maxHeight: 175,
    borderRadius: 16,
    marginLeft: 50,
    '& > div textarea': {
      '&:focus-visible': {
        outline: 'none !important'
      },
      minHeight: 'unset'
    }
  },
  sendBtn: {
    cursor: 'pointer',
    backgroundColor: '#00ACDB',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#00ACDB'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px',
    [theme.breakpoints.up('890')]: {
      marginTop: 16
    },
    whiteSpace: 'nowrap'
  },
  sendReplySection: {
    height: 'fit-content',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  replyHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    '& path': {
      stroke: '#28ACDC'
    },
    '& .icon-wrapper': {
      cursor: 'pointer',
      width: 'fit-content',
      height: 'fit-content',
      backgroundColor: 'rgba(0, 172, 219, 0.2)',
      borderRadius: 4
    }
  }
}))

const CommentComponent = ({ data, currentUser, eventId }) => {
  const currentReply = data.replies?.length > 0 && data.replies[0]
  const [enableEditReply, setEnableEditReply] = React.useState(!!currentReply)
  const [expandReplySection, setExpandReplySection] = React.useState(
    !!currentReply
  )

  // the useEffect is used here because the componente load the replies but the useState can't get his real value
  React.useEffect(
    () => {
      if (data.replies?.length > 0) {
        setExpandReplySection(true)
        setEnableEditReply(true)
      }
    },
    [data.replies]
  )

  const classes = useStyles()
  const dispatch = useDispatch()

  const handleSubmitCommentReply = () => {
    dispatch(submit(`commentReply_${data.id}`))
  }

  const FormWithId = React.useMemo(
    () => createReduxForm(`commentReply_${data.id}`, currentReply),
    [data.id]
  )

  return (
    <div>
      <div
        className={classes.commentHeader}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <UserAvatar
          userName={data.user.name}
          avatarStyle={{ width: 42, height: 42 }}
          avatarUrl={data?.avatar_url}
          style={{ color: '#ADB8CC' }}
        />
        <div className={classes.commentDate}>
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            <StyledSVG src={calendarSVG} height={15} width={15} />
            <span>{moment(data.created_at).format('DD/MM/YYYY')}</span>
          </div>
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            <StyledSVG src={clockSVG} height={15} width={15} />
            <span>{moment(data.created_at).format('HH:mm')}</span>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 10 }}>
        <CommentForm
          defaultValue={data.content}
          disabled
          textColor={'#ADB8CC'}
          bgColor={'#DADADA33'}
          hasBorder={false}
        />
      </div>
      <div
        className={`${classes.replySection} ${
          !expandReplySection ? classes.expandedReply : null
        }`}
      >
        <div
          onClick={() => {
            if (data.replies?.length < 1) {
              setExpandReplySection(!expandReplySection)
            }
          }}
          className={classes.replyBtn}
        >
          <StyledSVG src={replySVG} width={21} height={18} />
          <p className='reply'>Responder</p>
        </div>
      </div>
      <div
        className={`${classes.commentReply} ${
          !expandReplySection ? classes.commentReplyBorder : null
        }`}
        style={{
          height: expandReplySection ? '250px' : '0px'
        }}
      >
        <div className={classes.replyHeader}>
          <UserAvatar
            userName={currentUser.attributes.name}
            avatarStyle={{ width: 42, height: 42 }}
            avatarUrl={currentUser.attributes?.avatar_url}
            style={{ color: '#ADB8CC' }}
          />
          {currentReply?.user_id === +currentUser.id && (
            <div
              onClick={() => setEnableEditReply(!enableEditReply)}
              className='icon-wrapper'
            >
              <StyledSVG width={24} height={24} src={editSVG} />
            </div>
          )}
        </div>
        <FormWithId
          commentParentId={data.id}
          userId={currentUser.id}
          defaultValue={currentReply.content}
          eventId={eventId}
          disabled={enableEditReply}
          textColor={'#555555'}
          hasBorder={false}
        />

        {!enableEditReply ? (
          <div className={classes.sendReplySection}>
            <Button
              onClick={() => handleSubmitCommentReply()}
              className={classes.sendBtn}
            >
              Enviar
            </Button>
          </div>
        ) : (
          <div>
            <div
              className={classes.commentDate}
              style={{ marginLeft: 50, marginTop: 10 }}
            >
              <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                <StyledSVG src={calendarSVG} height={15} width={15} />
                <span>
                  {moment(currentReply.created_at).format('DD/MM/YYYY')}
                </span>
              </div>
              <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                <StyledSVG src={clockSVG} height={15} width={15} />
                <span>{moment(currentReply.created_at).format('HH:mm')}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const AnnouncementView = props => {
  const {
    eventType,
    eventId,
    currentItem,
    getEventById,
    destinations,
    isFetching,
    getEventDestinationById,
    cleanEventSuccessful,
    userOccupations,
    currentUser,
    destinationType,
    pagination,
    backToThePage,
    eventDestinationIsFetching
  } = props

  const classes = useStyles()
  const history = useHistory()
  const [destinationsModal, setDestinationsModal] = useState(false)
  const [eventLikeModal, setEventLikeModal] = useState(false)
  const [gradesModal, setGradesModal] = useState(false)
  const [arrayOfGrades, setArrayOfGrades] = useState([])
  const [textAreaVisible, setTextAreaVisible] = useState(false)
  const [classroomsModal, setClassroomsModal] = useState(false)
  const [usersModal, setUsersModal] = useState(false)
  const [arrayOfClassrooms, setArrayOfClassrooms] = useState([])
  const [arrayOfUsersDestinations, setArrayOfUsersDestinations] = useState([])
  const [showLoadingWarning, setShowLoadingWarning] = useState(false)

  React.useEffect(
    () => {
      if (isFetching) {
        setTextAreaVisible(false)
      }
      if (eventDestinationIsFetching) {
        const timeoutId = setTimeout(() => {
          setShowLoadingWarning(true)
        }, 15000)

        return () => clearTimeout(timeoutId)
      }
    },
    [isFetching, eventDestinationIsFetching]
  )
  React.useEffect(
    () => {
      if (currentItem.destinations) {
        const grades = currentItem.destinations.map(destination => {
          let classrooms = []
          if (destination.classroom) {
            classrooms = [destination.classroom]
          } else if (destination.user && destination.user.classrooms) {
            setArrayOfUsersDestinations(prevState => [
              ...prevState,
              destination.user
            ])
            classrooms = destination.user.classrooms
          }
          setArrayOfClassrooms(prevState => {
            if (
              !prevState.some(item =>
                classrooms.some(classroom => classroom.id === item.id)
              )
            ) {
              return [...prevState, ...classrooms]
            } else {
              return prevState
            }
          })
          if (destination.classroom && destination.classroom.grade) {
            return destination.classroom.grade
          } else if (destination.user && destination.user.classrooms) {
            return destination.user.classrooms.map(item => item.grade)
          }
        })
        const flatGrades = grades.flat()
        const filteredGrades = flatGrades.filter(grade => grade)
        setArrayOfGrades(prev => {
          let newState = [...filteredGrades]
          newState = newState.filter(
            (value, index, self) =>
              index === self.findIndex(grade => grade?.name === value?.name)
          )
          return newState
        })
      }
    },
    [currentItem.destinations]
  )

  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)

  useEffect(() => {
    getEventById({ id: eventId || currentItem.id })
    return () => {
      cleanEventSuccessful()
    }
  }, [])

  const handleDestinationsModal = () => {
    // cleanCurrentDestinationsEvent()
    setDestinationsModal(prevState => {
      if (prevState === true) {
        // to get the first page of destinations when close modal
        getEventDestinationById({
          id: currentItem.id,
          params: { 'page[number]': 1 }
        })
      }
      return !prevState
    })
  }
  const handleEventLikes = () => {
    setEventLikeModal(prevState => !prevState)
  }
  const handleGradesModal = () => {
    setGradesModal(prevState => !prevState)
  }

  const handleClassroomsModal = () => {
    setClassroomsModal(prevState => !prevState)
  }

  const handleUsersModal = () => {
    setUsersModal(prevState => !prevState)
  }

  const handleGetEventConfirmation = () => {
    history.push(`/announcements/${eventId}/confirmations`)
  }

  const handleViewsPage = () => {
    history.push(`/announcements/${eventId}/details`)
  }

  const hasConfirmation =
    eventType === 'events' ||
    (eventType !== 'events' && currentItem.confirmation_date)

  if (isFetching) return <LinearProgress color='secondary' />
  return (
    <div className={classes.root}>
      {!isAdmin &&
        currentItem?.status === 'refused' && (
          <ShowRejectAnnouncement rejectMessage={currentItem?.mod_note} />
        )}
      {!isAdmin &&
        currentItem?.status === 'pending' && <ShowPendingAnnouncement />}

      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        container
        item
        spacing={2}
        style={{
          backgroundColor: 'white',
          padding: 32,
          borderRadius: 8,
          margin: 0
        }}
      >
        {/* xs={3} sm={3} md={3} lg={3} xl={3} */}
        <Grid
          style={{ rowGap: 24 }}
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            className={classes.listForModal}
          >
            <div className={'info'}>
              <strong style={{ fontSize: 16 }}>Séries: </strong>
              <div style={{ display: 'flex', gap: 10 }}>
                {arrayOfGrades.length > 0 &&
                  arrayOfGrades.slice(0, 2).map(grade => (
                    <span
                      style={{ fontSize: 16, color: '#ADB8CC' }}
                      key={`grade[${grade.id}]`}
                    >
                      {grade.name}
                    </span>
                  ))}
                {arrayOfGrades &&
                  arrayOfGrades.length > 2 && (
                    <button
                      className={classes.viewMore}
                      type='button'
                      onClick={handleGradesModal}
                    >
                      Ver mais
                    </button>
                  )}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            className={classes.listForModal}
          >
            <div className={'info'}>
              <strong style={{ fontSize: 16 }}>Turmas: </strong>
              <div style={{ display: 'flex', gap: 10 }}>
                {arrayOfClassrooms &&
                  arrayOfClassrooms.slice(0, 1).map(classroom => (
                    <span
                      style={{ fontSize: 16, color: '#ADB8CC' }}
                      key={`classroom[${classroom.id}]`}
                    >
                      {classroom.name}
                    </span>
                  ))}
                {arrayOfClassrooms &&
                  arrayOfClassrooms.length > 1 && (
                    <button
                      className={classes.viewMore}
                      type='button'
                      onClick={handleClassroomsModal}
                    >
                      Ver mais
                    </button>
                  )}
              </div>
            </div>
          </Grid>
          {arrayOfUsersDestinations.length > 0 && (
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              className={classes.listForModal}
            >
              <div className={'info'}>
                <strong style={{ fontSize: 16 }}>Destinatários: </strong>
                <div style={{ display: 'flex', gap: 10 }}>
                  {arrayOfUsersDestinations.slice(0, 1).map(user => (
                    <span
                      style={{ fontSize: 16, color: '#ADB8CC' }}
                      key={`user[${user.id}]`}
                    >
                      {user.name}
                    </span>
                  ))}
                  {arrayOfUsersDestinations.length > 1 && (
                    <button
                      className={classes.viewMore}
                      type='button'
                      onClick={handleUsersModal}
                    >
                      Ver mais
                    </button>
                  )}
                </div>
              </div>
            </Grid>
          )}
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            className={classes.listForModal}
          >
            <div className={'info'}>
              <strong style={{ fontSize: 16 }}>Tipo de destinatário: </strong>
              <span style={{ fontSize: 16, color: '#ADB8CC', margin: 0 }}>
                {destinationType}
              </span>
            </div>
          </Grid>

          {eventType === 'events' && (
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                className={classes.listForModal}
              >
                <div className={'info'}>
                  <strong style={{ fontSize: 16 }}>Data evento:</strong>
                  <span style={{ fontSize: 16, color: '#ADB8CC' }}>
                    {moment(currentItem.event_date).format('DD/MM/YYYY')}
                  </span>
                </div>
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                className={classes.listForModal}
              >
                <div className={'info'}>
                  <strong style={{ fontSize: 16 }}>Hora início:</strong>
                  <p>{moment(currentItem.start_time).format('HH:mm')}</p>
                </div>
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                className={classes.listForModal}
              >
                <div className={'info'}>
                  <strong style={{ fontSize: 16 }}>Hora término:</strong>
                  <span style={{ fontSize: 16, color: '#ADB8CC' }}>
                    {moment(currentItem.end_time).format('HH:mm')}
                  </span>
                </div>
              </Grid>
            </Grid>
          )}

          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            className={classes.listForModal}
          >
            <div className={'info'}>
              <strong style={{ fontSize: 16 }}>Publicação: </strong>
              <span style={{ fontSize: 16, color: '#ADB8CC' }}>
                {moment(currentItem.scheduled_at).format('DD/MM/YYYY HH:mm')}
              </span>
            </div>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            className={classes.listForModal}
          >
            <div className={'info'}>
              <strong style={{ fontSize: 16 }}>Criação: </strong>
              <span style={{ fontSize: 16, color: '#ADB8CC' }}>
                {moment(currentItem.created_at).format('DD/MM/YYYY HH:mm')}
              </span>
            </div>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            className={classes.listForModal}
          >
            <div className={'info'}>
              <strong style={{ fontSize: 16 }}>Status:</strong>
              <span style={{ fontSize: 16, color: '#ADB8CC' }}>
                {statusEvent[currentItem.status]}
              </span>
            </div>
          </Grid>
          {hasConfirmation && (
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              className={classes.listForModal}
            >
              <div className={'info'}>
                <strong style={{ fontSize: 16 }}>Confirmação até:</strong>
                <span style={{ fontSize: 16, color: '#ADB8CC' }}>
                  {currentItem.confirmation_date
                    ? moment(currentItem.confirmation_date).format('DD/MM/YYYY')
                    : '-'}
                </span>
              </div>
            </Grid>
          )}

          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            className={classes.listForModal}
          >
            <div className={'info'}>
              <strong style={{ fontSize: 16 }}>Curtidas:</strong>{' '}
              <div className={classes.detailsSection}>
                <div style={{ disclay: 'block' }}>
                  <img
                    src={heartSVG}
                    alt='icone curtidas'
                    width='13'
                    height='13'
                  />{' '}
                  <span style={{ fontSize: 16, color: '#ADB8CC' }}>
                    {String(currentItem.event_likes?.length || '0').padStart(
                      2,
                      '0'
                    )}
                  </span>
                </div>
                <button
                  className={classes.viewMore}
                  type='button'
                  onClick={handleEventLikes}
                >
                  Ver detalhes
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{
          marginBottom: 16
        }}
        item
        spacing={2}
      >
        <div className={classes.midContent}>
          <div className='firstColumn'>
            <div
              className={`${classes.sectionsIntoMidContent} ${classes.title}`}
              style={{ height: '20%' }}
            >
              <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                <StyledSVG width={24} height={24} src={titleSVG} />
                <h1 className='title' style={{ fontSize: 24, margin: 0 }}>
                  Título
                </h1>
              </div>
              <p style={{ marginTop: 0, color: '#ADB8CC' }}>
                {currentItem.title}
              </p>
            </div>
            <div
              className={`${classes.sectionsIntoMidContent} ${
                classes.description
              }`}
              style={{ height: '80%', overflow: 'overlay' }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between'
                }}
              >
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                  <StyledSVG width={24} height={24} src={descriptionSVG} />
                  <h1
                    className='description'
                    style={{ fontSize: 24, margin: 0 }}
                  >
                    Descrição
                  </h1>
                </div>
              </div>
              <p
                style={{
                  wordBreak: 'break-all',
                  lineHeight: '120%',
                  color: '#ADB8CC',
                  maxHeight: 400
                }}
                dangerouslySetInnerHTML={{
                  __html: `${currentItem?.description}`
                }}
              />
            </div>
          </div>
          <div className='secondColumn'>
            <div
              className={`${classes.sectionsIntoMidContent}`}
              style={{
                height: hasConfirmation ? '80%' : '85%',
                flexDirection: 'column',
                justifyContent: 'flex-start'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  gap: 30,
                  ...(eventDestinationIsFetching
                    ? { flexDirection: 'column', height: '100%' }
                    : { justifyContent: 'space-between' })
                }}
              >
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                  <StyledSVG width={24} height={24} src={destinationsSVG} />
                  <h1
                    className='destinations'
                    style={{ fontSize: 24, margin: 0 }}
                  >
                    Destinatário(s)
                  </h1>
                </div>
                {eventDestinationIsFetching && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column'
                    }}
                  >
                    <CircularProgress size={80} variant='indeterminate' />
                    <h4
                      style={{
                        textAlign: 'center',
                        maxWidth: '20vw',
                        userSelect: 'none'
                      }}
                      onClick={() => {
                        if (showLoadingWarning) {
                          setShowLoadingWarning(false)
                        }
                      }}
                      className={
                        showLoadingWarning ? classes.fadeIn : classes.fadeOut
                      }
                    >
                      A operação está demorando mais do que o esperando devido a
                      grande quantidade de destinatários.
                    </h4>
                  </div>
                )}
                {destinations?.items &&
                  destinations?.items.length > 0 &&
                  !eventDestinationIsFetching && (
                    <button
                      className={classes.viewMore}
                      type='button'
                      onClick={handleDestinationsModal}
                    >
                      Ver mais
                    </button>
                  )}
              </div>
              {destinations?.items &&
                !eventDestinationIsFetching &&
                destinations?.items?.length > 0 && (
                  <PaginatedAsyncTable
                    columns={[
                      {
                        key: 'name',
                        name: 'Nome',
                        render: function render (row) {
                          return (
                            <UserAvatar
                              userName={row.name}
                              avatarStyle={{ width: 24, height: 24 }}
                              avatarUrl={row?.avatar_url}
                              style={{ color: '#ADB8CC' }}
                            />
                          )
                        }
                      }
                    ]}
                    withoutPageCounter
                    whiteHeader
                    minWidth={'unset'}
                    borderBottom={'none'}
                    pageSize={8}
                    data={destinations?.items}
                  />
                )}
            </div>
            <div
              className={`${classes.sectionsIntoMidContent} ${
                classes.viewedBy
              }`}
              style={{
                height: hasConfirmation ? '10%' : '15%',
                cursor: 'pointer'
              }}
              onClick={handleViewsPage}
            >
              <StyledSVG width={80} height={55} src={viewedSVG} />
              <h1>Visualizados: {currentItem?.event_views?.length}</h1>
            </div>
            <div
              className={`${classes.sectionsIntoMidContent} ${
                classes.confirmedBy
              }`}
              style={{
                display: !hasConfirmation ? 'none' : '',
                height: '10%',
                cursor: 'pointer'
              }}
              onClick={handleGetEventConfirmation}
            >
              <StyledSVG width={80} height={55} src={confirmedSVG} />
              <h1>Confirmados: {currentItem?.participants?.length}</h1>
            </div>
          </div>
        </div>
      </Grid>

      {(currentItem?.cover_image_url || currentItem?.files?.length > 0) && (
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            rowGap: '30px',
            backgroundColor: 'white',
            padding: 32,
            borderRadius: 8,
            margin: 0
          }}
          item
          spacing={2}
        >
          <div>
            <div
              style={{
                display: 'flex',
                gap: 8,
                alignItems: 'center',
                marginBottom: 24
              }}
            >
              <StyledSVG width={24} height={24} src={titleSVG} />
              <h1 style={{ fontSize: 24, margin: 0 }}>Arquivos</h1>
            </div>
            <ShowEventFiles
              mainImage={currentItem.cover_image_url}
              images={
                !!currentItem.files?.length &&
                currentItem.files.filter(
                  item =>
                    item.content_type?.includes('image') ||
                    item.content_type?.includes('video')
                )
              }
              otherFiles={
                !!currentItem.files?.length &&
                currentItem.files.filter(
                  item =>
                    !item.content_type?.includes('image') &&
                    !item.content_type?.includes('video')
                )
              }
            />
          </div>
        </Grid>
      )}
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{
          rowGap: '30px',
          backgroundColor: 'white',
          padding: 32,
          borderRadius: 8,
          marginTop: 16
        }}
        item
        spacing={2}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 24,
            justifyContent: 'space-between'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <StyledSVG height={24} width={24} src={commentsSVG} />
            <h1 className={classes.comment}>
              Comentários ({String(
                currentItem.event_comments?.length || '0'
              ).padStart(2, '0')})
            </h1>
          </div>
          <div>
            <div
              className={classes.commentBtn}
              style={{
                display: isAdmin ? 'none' : 'flex'
              }}
            >
              <AddCircleOutlineIcon />
              <p
                onClick={() => setTextAreaVisible(prevState => !prevState)}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                Comentar
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            maxHeight: textAreaVisible ? '100%' : '0',
            overflow: 'hidden',
            transition: 'max-height 0.5s ease-in-out'
          }}
        >
          <CommentForm
            firstComment
            userId={currentUser.id}
            eventId={eventId}
            style={{
              opacity: textAreaVisible ? '1' : '0',
              transition: 'opacity 0.3s ease-in-out'
            }}
          />
        </div>

        {currentItem?.event_comments?.length > 0 &&
          [...currentItem.event_comments]
            .sort((x, y) => new Date(y.created_at) - new Date(x.created_at))
            .filter(item => item.comment_type === 'main_comment')
            .map(comment => {
              return (
                <CommentComponent
                  eventId={eventId}
                  currentUser={currentUser}
                  key={comment.id}
                  data={comment}
                />
              )
            })}
      </Grid>
      <Grid item xs={12} className={classes.goBackContainer}>
        <Button
          variant='outlined'
          className={classes.backBtn}
          type='button'
          onClick={() => {
            backToThePage({ ...pagination, canGoBack: true })
            history.push(
              `/announcements?name=list&event=${currentItem.event_type}`
            )
          }}
        >
          Voltar
        </Button>
      </Grid>
      <MaterialModal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={destinationsModal}
        onClose={handleDestinationsModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={destinationsModal}>
          <div className={classes.modalSection}>
            <CustomTable
              columns={[
                {
                  key: 'name',
                  name: 'Nome',
                  width: '35%',
                  render: function render (row) {
                    return row.name
                  }
                },
                {
                  key: 'email',
                  name: 'E-mail',
                  width: '35%',
                  render: function render (row) {
                    return row.email
                  }
                }
              ]}
              border={'none'}
              bodyBorder={'none'}
              headCellRadius
              headColor={'rgba(173, 184, 204, 0.3)'}
              paramId={currentItem.id}
              data={destinations.items}
              fetchItems={getEventDestinationById}
              isFetching={destinations.isFetching}
              pagination={destinations.pagination}
            />
          </div>
        </Fade>
      </MaterialModal>
      <MaterialModal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={eventLikeModal}
        onClose={handleEventLikes}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={eventLikeModal}>
          <PaginatedAsyncTable
            columns={[
              {
                key: 'name',
                name: 'Nome',
                width: '30%',
                render: function render (row) {
                  return row.user.name
                }
              },
              {
                key: 'email',
                name: 'E-mail',
                width: '30%',
                render: function render (row) {
                  return row.user.email
                }
              },
              {
                key: 'liked_at',
                name: 'Data da curtida',
                width: '20%',
                render: function render (row) {
                  return moment(row.liked_at).format('YYYY/MM/DD HH:mm')
                }
              },
              {
                key: 'user_role',
                name: 'Ocupação',
                width: '20%',
                render: function render (row) {
                  return occupationsObject[row.user_role]
                }
              }
            ]}
            data={currentItem.event_likes || []}
            headBorder={'none'}
            borderBottom={'none'}
            headCellRadius
            headColor={'rgba(173, 184, 204, 0.3)'}
            className={classes.tableClassname}
          />
        </Fade>
      </MaterialModal>

      <MaterialModal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={gradesModal}
        onClose={handleGradesModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={gradesModal}>
          <div className={classes.modalRoot}>
            <div className={classes.closeIcon} onClick={handleGradesModal}>
              <CloseRoundedIcon />
            </div>
            <h2 id='modal-title'>Séries:</h2>
            <PaginatedAsyncTable
              columns={[
                {
                  key: 'grade',
                  name: 'Série',
                  width: '35%',
                  render: function render (row) {
                    return row.name
                  }
                },
                {
                  key: 'segment',
                  name: 'Segmento',
                  width: '35%',
                  render: function render (row) {
                    return row.segment.name
                  }
                }
              ]}
              headBorder={'none'}
              borderBottom={'none'}
              headCellRadius
              headColor={'rgba(173, 184, 204, 0.3)'}
              pageSize={5}
              data={arrayOfGrades.sort((a, b) => {
                if (a.segment_id < b.segment_id) return -1
                if (a.segment_id > b.segment_id) return 1
                return 0
              })}
            />
          </div>
        </Fade>
      </MaterialModal>
      <MaterialModal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={usersModal}
        onClose={handleUsersModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={usersModal}>
          <div className={classes.modalRoot}>
            <div className={classes.closeIcon} onClick={handleUsersModal}>
              <CloseRoundedIcon />
            </div>
            <h2 id='modal-title'>Destinatários:</h2>
            <PaginatedAsyncTable
              columns={[
                {
                  key: 'user',
                  name: 'Usuário',
                  width: '35%',
                  render: function render (row) {
                    return row.name
                  }
                }
              ]}
              headBorder={'none'}
              borderBottom={'none'}
              headCellRadius
              headColor={'rgba(173, 184, 204, 0.3)'}
              pageSize={5}
              data={arrayOfUsersDestinations.sort((a, b) => {
                if (a.name.toUpperCase() < b.name.toUpperCase()) return -1
                if (a.name.toUpperCase() > b.name.toUpperCase()) return 1
                return 0
              })}
            />
          </div>
        </Fade>
      </MaterialModal>
      <MaterialModal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={classroomsModal}
        onClose={handleClassroomsModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={classroomsModal}>
          <div className={classes.modalRoot}>
            <div className={classes.closeIcon} onClick={handleClassroomsModal}>
              <CloseRoundedIcon />
            </div>
            <h2 id='modal-title'>Turmas:</h2>
            <PaginatedAsyncTable
              columns={[
                {
                  key: 'classroom',
                  name: 'Turma',
                  width: '35%',
                  render: function render (row) {
                    return row.name
                  }
                },
                {
                  key: 'grade',
                  name: 'Série',
                  width: '35%',
                  render: function render (row) {
                    return row.grade.name
                  }
                },
                {
                  key: 'shift',
                  name: 'Turno',
                  width: '35%',
                  render: function render (row) {
                    return reportsShift[row.shift]
                  }
                }
              ]}
              headBorder={'none'}
              borderBottom={'none'}
              headCellRadius
              headColor={'rgba(173, 184, 204, 0.3)'}
              pageSize={5}
              withoutPageCounter={arrayOfClassrooms.length < 5}
              data={arrayOfClassrooms.sort((a, b) => {
                if (a.name.toUpperCase() < b.name.toUpperCase()) return -1
                if (a.name.toUpperCase() > b.name.toUpperCase()) return 1
                return 0
              })}
            />
          </div>
        </Fade>
      </MaterialModal>
    </div>
  )
}

const mapStateToProps = ({ events, auth, school, pagination }) => {
  const destinationTypes = new Set(
    events?.currentItem?.destinations?.map(destination => {
      return destination.destination_type
        ? destinationTypeEvent[destination.destination_type]
        : 'Todos'
    })
  )

  return {
    userOccupations: auth.currentOccupation,
    currentItem: events.currentItem,
    isFetching: events.isFetching,
    destinations: events.destinations,
    destinationType:
      destinationTypes?.size > 0
        ? Array.from(destinationTypes)
            .map((item, index) => {
              return index !== 0 ? item.toLowerCase() : item
            })
            .join(', ')
        : '',
    currentUser: auth.user.data,
    classroomsIds: events?.currentItem?.destinations?.map(destination => {
      return destination.classroom_id
    }),
    schoolId: school?.currentSchool?.school_id,
    pagination: pagination,
    eventDestinationIsFetching: events?.destinations?.isFetching
  }
}

const mapDispatchToProps = dispatch => ({
  getEventById: data => dispatch(getEventById(data)),
  getEventDestinationById: data => dispatch(getEventDestinationById(data)),
  cleanEventSuccessful: () => dispatch(cleanEventSuccessful()),
  cleanCurrentDestinationsEvent: () =>
    dispatch(cleanCurrentDestinationsEvent()),
  backToThePage: data => dispatch(backToThePage(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementView)
